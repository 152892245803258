import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const RangeSelector = ({ rangeData, setRangeData, maxRange }) => {

  const handleChange = (event, newValue) => {
    setRangeData(newValue);
  };

  return (
    <Box sx={{ width: 270, marginLeft: "10px", marginRight: "10px" }}>
      <Slider
        value={rangeData}
        onChange={handleChange}
        min={0}
        max={maxRange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
    </Box>
  );
};

export default RangeSelector;
