import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IoIosArrowBack } from "react-icons/io";
import { Typography, Paper, Box, Button, Grid } from "@mui/material";
import moment from "moment";

import Axios from "../../utils/axios";
import Loader from "./Loader";

// Styled components for custom styling
const CalendarContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  width: 100%;
`;

const DateContainer = styled.div`
  box-shadow: 0px 0px 12.1px 0px #0000001c;
`;

const SlotContainer = styled.div`
  box-shadow: 0px 0px 12.1px 0px #0000001c;
`;

const DatePaper = styled(Paper)`
  padding: 8px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#cfe8fc" : "inherit")};
  box-shadow: none;
  border-bottom: 1px solid #efefef;
  border-radius: 0px;
  color: ${(props) => props.template === "magicbrick" || props.template === "lundiniom" ? "#fff" : "#000"};
  &:hover {
    background-color: ${(props) =>
    props.template === "btc"
      ? "#202020"
        :  props.template === "talbies"
      ? "#000"
      : props.template === "charlessinclair"
      ? "#c7b354"
      
      : props.template === "denhan"
        ? "#b82142"
        : props.template === "daniels"
          ? "#FED838"
          : props.template === "magicbrick"
            ? "#ffcc00"
            : props.template === "lundiniom"
              ? "#ffc800"
              : "#ac6dfd"};
    color: ${(props) =>
    props.template === "btc"
      ? "#ffffff"
      :  props.template === "talbies"
      ? "#fff"
      
      :
      props.template === "charlessinclair"
      ? "#fff"
      :
       props.template === "denhan"
        ? "#ffffff"
        : props.template === "magicbrick"
          ? "#000"
          : props.template === "daniels"
            ? "#000"
            : props.template === "lundiniom"
              ? "#000"
              : "#fff"};
  }
`;

const HeaderTypography = styled(Typography)`
  padding: 0;
  height: 40px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: ${(props) => props.template === "magicbrick" || props.template === "lundiniom" ? "#fff" : "#000"};
  font-weight: 800;
`;

const BackIcon = styled(IoIosArrowBack)`
  width: 15px;
  font-size: 15px;
  height: 15px;
  margin: 8px;
  margin-bottom: 0;
  color: ${(props) => props.template === "magicbrick" || props.template === "lundiniom" ? "#fff" : "#000"};
  cursor: pointer;
`;

const SlotButton = styled(Button)`
  padding: 8px;
  background-color: ${(props) => (props.slotAvailable ? "inherit" : "#eee")};
  width: 100%;
  display: ${(props) => (!props.slotAvailable ? "none" : "block")};
  color: ${(props) => props.template === "magicbrick" || props.template === "lundiniom" ? "#fff" : "#000"};
  &:hover {
    background-color: ${(props) =>
    props.template === "btc"
      ? "#3c6e95"
      :
      props.template === "talbies"
      ? "#202020"
      
      :
      props.template === "charlessinclair"
      ? "#c7b354"
      :
       props.template === "denhan"
        ? "#b82142"
        : props.template === "daniels"
          ? "#FED838"
          : props.template === "magicbrick"
            ? "#ffcc00"
            : props.template === "lundiniom"
              ? "#ffc800"
              : "#ac6dfd"};
    color: ${(props) =>
    props.template === "btc"
      ? "#ffffff"
      : props.template === "talbies"
      ? "#fff"
      
      :
      props.template === "charlessinclair"
      ? "#fff"
      
      : 
      props.template === "denhan"
        ? "#ffffff"
        : props.template === "magicbrick"
          ? "#000"
          : props.template === "daniels"
            ? "#000"
            : props.template === "lundiniom"
              ? "#000"
              : "#fff"};
  }
`;

// BotCal component
const BotCal = ({
  handleSendDailogApi,
  chatId,
  chatBotAdminData,
  stopSendloading,
  messages,
  template,
  apiBaseUrl,
}) => {
  // State initialization
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotsData, setSlotsData] = useState([]);
  const [dateLoad, setDateLoad] = useState(false);

  // Fetch slots data from the server
  useEffect(() => {
    const fetchData = async () => {
      setDateLoad(true);
      try {
        // Prepare parameters for the API request
        const params = {
          userid: chatBotAdminData.userId,
          isAgency: true,
          department: messages?.slice(-1)[0]?.category,
          tZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          sender: localStorage?.getItem(`chat-session-Id-${chatId}`)
        };
        // Fetch data from the API
        const response = await Axios.get(`${apiBaseUrl}/todo/get-slots-availability`, { params });

        if (response.data.data) {
          // Process the response data
          setDateLoad(false);
          const currentDateTime = moment();
          const filteredSlotsData = response.data.data.map((dateObj) => {
            return {
              date: dateObj.date,
              slots: dateObj.slots.filter(
                (slot) =>
                  moment(`${dateObj.date} ${slot.endTime}`, "MM/DD/YYYY hh:mm A").isAfter(currentDateTime) &&
                  moment(`${dateObj.date} ${slot.startTime}`, "MM/DD/YYYY hh:mm A").isAfter(currentDateTime)
              ),
            };
          });
          // Update state with the filtered slots data
          setSlotsData(filterUnavailableDates(filteredSlotsData));
        }
      } catch (error) {
        setDateLoad(false);
      }
    };

    fetchData();
  }, []);

  // Handler function for date click
  const handleDateClick = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };

  // Handler function for slot click
  const handleSlotClick = (slot) => {
    if (slot.slotAvailable) {
      setSelectedSlot(slot);
    }
  };

  function filterUnavailableDates(mergedSlots) {
    // Filter out dates with no slots or all slots unavailable
    return mergedSlots.filter(({ slots }) => {
      return slots.length > 0 && slots.some(slot => slot.slotAvailable);
    });
  }
  // Render the component
  return (
    <>
      {!dateLoad ? (
        <CalendarContainer className={template === "lundiniom" ? 'lundiniom-calendar' : ''} >
          <DateContainer hidden={selectedDate}>
            <Typography variant="p" sx={{
              padding: 1, height: "25px", lineHeight: "30px", paddingBottom: "0", fontWeight: "700",
              marginTop: "4px", textAlign: "left", color: (template === "magicbrick" || template === "lundiniom") ? "#fff" : "#000"
            }}>
              Choose a date
            </Typography>
            <Box sx={{ overflowY: "auto", height: "250px" }}>
              {slotsData.map((dateObj) => (
                <DatePaper
                  key={dateObj.date}
                  onClick={() => handleDateClick(dateObj.date)}
                  selected={selectedDate === dateObj.date}
                  template={template}

                >
                  <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "14px" }}>
                    <div>{moment(dateObj.date).format("dddd")}</div>
                    <div>{moment(dateObj.date).format("MMMM D, YYYY")}</div>
                  </Typography>
                </DatePaper>
              ))}
            </Box>
          </DateContainer>

          <SlotContainer hidden={!selectedDate}>
            <HeaderTypography template={template}>
              <span onClick={() => { setSelectedDate(null); setSelectedSlot(null); }}>
                <BackIcon template={template} />
              </span>
              {moment(selectedDate).format("dddd, MMMM D")}
            </HeaderTypography>

            {selectedDate && (
              <Box sx={{ overflowY: "auto", height: "250px", overflowX: "hidden" }}>
                <Grid container spacing={0} className="mt-2">
                  {slotsData.find((dateObj) => dateObj.date === selectedDate)?.slots.map((slot, idx) => (
                    <Grid
                      key={idx} xs={12} sm={12} md={6}
                      sx={{ borderRight: "1px solid #efefef", borderBottom: "1px solid #efefef", display: !slot.slotAvailable ? "none" : "" }}
                    >
                      <SlotButton
                        onClick={() => {
                          if (!stopSendloading) {
                            handleSlotClick(slot);
                            handleSendDailogApi(moment(selectedDate + " " + slot.startTime).format(), chatId, true);
                          }
                        }}
                        disabled={stopSendloading}
                        slotAvailable={slot.slotAvailable}
                        template={template}
                        hidden={!slot.slotAvailable}  >
                        {slot.startTime}
                      </SlotButton>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </SlotContainer>
        </CalendarContainer>
      ) : (
        <Loader size="medium" />
      )}
    </>
  );
};

export default BotCal;
